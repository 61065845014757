import baseAPI from '@/api/base';
import { EditAlias, GetAlias, RegisterAlias } from '@/dtos/alias.dto';
import { DELETE_ALIAS_URL, ALIAS_URL, EDIT_ALIAS_URL, ADD_ALIAS_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Alias {
    static getAliass = async(getAlias: GetAlias) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(ALIAS_URL, {
                    params: getAlias
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerAlias = async(registerAlias: RegisterAlias) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_ALIAS_URL, {
                    ...registerAlias
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editAlias = async(editAlias: EditAlias) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_ALIAS_URL, {
                    ...editAlias
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteAlias = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_ALIAS_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Alias