// account
export const LOGIN = 'login'
export const SIGNUP = 'signup'
export const LOGOUT = 'logout'
export const UPDATE_ACCOUNT = 'updateAccount'
export const GET_AUTH_ACCOUNT = 'getAuthAccount'
export const CLEAR_ACCOUNT_ISLOADING = 'clearAccountIsLoading'

// donation
export const REGISTER_DONATION = 'registerDonation'
export const GET_DONATIONS = 'getDonations'
export const GET_DISPLAY_DONATIONS = 'getDisplayDonations'
export const GET_CALENDAR_DONATIONS = 'getCalendarDonations'
export const GET_MEMBER_DONATIONS = 'getMemberDonations'
export const DELETE_DONATION = 'deleteDonation'
export const EDIT_DONATION = 'editDonation'
export const TOGGLE_DONATION_IS_ACTIVE = 'toggleDonationIsActive'
export const CLEAR_DISPLAY_DONATIONS = 'clearDisplayDonation'
export const CLEAR_DONATION_ISLOADING = 'clearDonationIsLoading'

// template
export const ADD_TEMPLATE = 'registerTemplate'
export const GET_TEMPLATES = 'getTemplates'
export const GET_CALENDAR_TEMPLATES = 'getCalendarTemplates'
export const DELETE_TEMPLATE = 'deleteTemplate'
export const EDIT_TEMPLATE = 'editTemplate'
export const CLEAR_TEMPLATE_ISLOADING = 'clearTemplateIsLoading'

// announcement
export const ADD_ANNOUNCEMENT = 'registerAnnouncement'
export const GET_ANNOUNCEMENTS = 'getAnnouncements'
export const GET_CALENDAR_ANNOUNCEMENTS = 'getCalendarAnnouncements'
export const DELETE_ANNOUNCEMENT = 'deleteAnnouncement'
export const EDIT_ANNOUNCEMENT = 'editAnnouncement'
export const CLEAR_ANNOUNCEMENT_ISLOADING = 'clearAnnouncementIsLoading'

// screen
export const ADD_SCREEN = 'registerScreen'
export const GET_SCREEN = 'getScreen'
export const GET_SCREENS = 'getScreens'
export const GET_CALENDAR_SCREENS = 'getCalendarScreens'
export const EDIT_SCREEN = 'editScreen'
export const REFRESH_SCREEN = 'refreshScreen'
export const DELETE_SCREEN = 'deleteScreen'
export const CLEAR_SCREEN_ISLOADING = 'clearScreenIsLoading'

// organization
export const REGISTER_ORGANIZATION = 'registerOrganization'
export const GET_ORGANIZATION = 'getOrganization'
export const GET_ORGANIZATIONS = 'getOrganizations'
export const GET_CALENDAR_ORGANIZATIONS = 'getCalendarOrganizations'
export const DELETE_ORGANIZATION = 'deleteOrganization'
export const EDIT_ORGANIZATION = 'editOrganization'
export const TOGGLE_ORGANIZATION_IS_ACTIVE = 'toggleOrganizationIsActive'
export const CLEAR_ORGANIZATION_ISLOADING = 'clearOrganizationIsLoading'

// member
export const ADD_MEMBER = 'registerMember'
export const GET_MEMBERS = 'getMembers'
export const GET_FILTERED_MEMBERS = 'getFilteredMembers'
export const GET_MEMBER = 'getMember'
export const GET_CALENDAR_MEMBERS = 'getCalendarMembers'
export const DELETE_MEMBER = 'deleteMember'
export const EDIT_MEMBER = 'editMember'
export const CLEAR_MEMBERS_ISLOADING = 'clearMembersIsLoading'

// purpose
export const ADD_PURPOSE = 'registerPurpose'
export const GET_PURPOSES = 'getPurposes'
export const GET_MEMBER_PURPOSES = 'getMemberPurposes'
export const GET_CALENDAR_PURPOSES = 'getCalendarPurposes'
export const EDIT_PURPOSE = 'editPurpose'
export const DELETE_PURPOSE = 'deletePurpose'
export const CLEAR_PURPOSE_ISLOADING = 'clearPurposeIsLoading'

// notification
export const GET_NOTIFICATIONS = 'getNotifications'
export const GET_ALL_NOTIFICATIONS = 'getAllNotifications'
export const CLEAR_NOTIFICATIONS = 'cleatNotifications'
export const CLEAR_NOTIFICATIONS_ISLOADING = 'clearNotificationIsLoading'

// users
export const GET_USERS = 'getUsers'
export const REGISTER_USER = 'registerUser'
export const EDIT_USER = 'editUser'
export const TOGGLE_USER_IS_ACTIVE = 'toggleUserIsActive'
export const DELETE_USER = 'deleteUser'
export const CLEAR_USERS_ISLOADING = 'clearUsersIsLoading'

// minyanim
export const ADD_MINYANIM = 'registerMinyanim'
export const GET_MINYANIMS = 'getMinyanims'
export const GET_DISPLAY_MINYANIMS = 'getDisplayMinyanims'
export const DELETE_MINYANIM = 'deleteMinyanim'
export const TOGGLE_MINYANIM_IS_ACTIVE = 'toggleMinyanimIsActive'
export const EDIT_MINYANIM = 'editMinyanim'
export const CLEAR_MINYANIMS_ISLOADING = 'clearMinyanimsIsLoading'

// room
export const REGISTER_ROOM = 'registerRoom'
export const GET_ROOMS = 'getRooms'
export const DELETE_ROOM = 'deleteRoom'
export const EDIT_ROOM = 'editRoom'
export const CLEAR_ROOMS_ISLOADING = 'clearRoomsIsLoading'

// alert
export const ADD_ALERT = 'addAlert'
export const GET_ALERTS = 'getAlerts'
export const GET_ALERT = 'getAlert'
export const DELETE_ALERT = 'deleteAlert'
export const EDIT_ALERT = 'editAlert'
export const CLEAR_ALERTS_ISLOADING = 'clearAlertsIsLoading'

// alert_notification
export const GET_ALERT_NOTIFICATIONS = 'getAlertNotifications'
export const GET_ALERT_NOTIFICATION = 'getAlertNotification'
export const VIEW_ALERT_NOTIFICATION = 'editAlertNotification'
export const CLEAR_ALERT_NOTIFICATIONS_ISLOADING = 'clearAlertNotificationsIsLoading'

// ticket
export const ADD_TICKET = 'addTicket'
export const GET_TICKETS = 'getTickets'
export const DELETE_TICKET = 'deleteTicket'
export const EDIT_TICKET = 'editTicket'
export const CLOSE_TICKET = 'closeTicket'
export const CLEAR_TICKETS_ISLOADING = 'clearTicketsIsLoading'

// ticket
export const ADD_TICKET_COMMENT = 'addTicketComment'
export const GET_TICKET_COMMENTS = 'getTicketComments'
export const DELETE_TICKET_COMMENT = 'deleteTicketComment'
export const EDIT_TICKET_COMMENT = 'editTicketComment'
export const CLEAR_TICKET_COMMENTS = 'clearTicketComments'
export const CLEAR_TICKET_COMMENTS_ISLOADING = 'clearTicketCommentsIsLoading'

// minyanim_type
export const ADD_MINYANIM_TYPE = 'addMinyanimType'
export const GET_MINYANIM_TYPES = 'getMinyanimTypes'
export const DELETE_MINYANIM_TYPE = 'deleteMinyanimType'
export const EDIT_MINYANIM_TYPE = 'editMinyanimType'
export const CLEAR_MINYANIM_TYPES = 'clearMinyanimTypes'
export const CLEAR_MINYANIM_TYPES_ISLOADING = 'clearMinyanimTypesIsLoading'

// shiurim
export const ADD_SHIURIM = 'registerShiurim'
export const GET_SHIURIMS = 'getShiurims'
export const DELETE_SHIURIM = 'deleteShiurim'
export const EDIT_SHIURIM = 'editShiurim'
export const CLEAR_SHIURIM_ISLOADING = 'clearShiurimIsLoading'

// synagogue
export const REGISTER_SYNAGOGUE = 'registerSynagogue'
export const GET_SYNAGOGUES = 'getSynagogues'
export const DELETE_SYNAGOGUE = 'deleteSynagogue'
export const EDIT_SYNAGOGUE = 'editSynagogue'
export const CLEAR_SYNAGOGUES_ISLOADING = 'clearSynagoguesIsLoading'

// synagogue_seat
export const GET_SYNAGOGUE_SEATS = 'getSynagogues'
export const CLEAR_SYNAGOGUE_SEATS_ISLOADING = 'clearSynagoguesIsLoading'

// invoice
export const REGISTER_INVOICE = 'registerInvoice'
export const GET_INVOICES = 'getInvoices'
export const GET_MEMBER_INVOICES = 'getMemberInvoices'
export const DELETE_INVOICE = 'deleteInvoice'
export const EDIT_INVOICE = 'editInvoice'
export const PAY_INVOICE = 'payInvoice'
export const CLEAR_INVOICES_ISLOADING = 'clearInvoicesIsLoading'

// invoice_item
export const GET_INVOICE_ITEMS = 'getInvoiceItems'
export const GET_INVOICE_ITEM_DETAILS = 'getInvoiceItemDetails'
export const TOGGLE_INVOICE_ITEM_ISPAID = 'toggleInvoiceItemIsPaid'
export const CLEAR_INVOICE_ITEMS_ISLOADING = 'clearInvoiceItemsIsLoading'

// invoice_item_payment_log
export const GET_INVOICE_ITEM_PAYMENT_LOGS = 'getInvoiceItemPaymentLogs'
export const GET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS = 'getSingleInvoiceItemPaymentLogs'
export const CLEAR_INVOICE_ITEM_PAYMENT_LOGS_ISLOADING = 'clearInvoiceItemPaymentLogsIsLoading'

// synagogue_seat_price_category
export const ADD_SYNAGOGUE_SEAT_PRICE_CATEGORY = 'registerSynagogueSeatPriceCategory'
export const GET_SYNAGOGUE_SEAT_PRICE_CATEGORIES = 'getSynagogueSeatPriceCategories'
export const DELETE_SYNAGOGUE_SEAT_PRICE_CATEGORY = 'deleteSynagogueSeatPriceCategory'
export const EDIT_SYNAGOGUE_SEAT_PRICE_CATEGORY = 'editSynagogueSeatPriceCategory'
export const CLEAR_SYNAGOGUE_SEAT_PRICE_CATEGORIES_ISLOADING = 'clearSynagogueSeatPriceCategoriesIsLoading'

// synagogue_seat_pricing
export const ADD_SYNAGOGUE_SEAT_PRICING = 'registerSynagogueSeatPricing'
export const GET_SYNAGOGUE_SEAT_PRICINGS = 'getSynagogueSeatPricings'
export const DELETE_SYNAGOGUE_SEAT_PRICING = 'deleteSynagogueSeatPricing'
export const EDIT_SYNAGOGUE_SEAT_PRICING = 'editSynagogueSeatPricing'
export const CLEAR_SYNAGOGUE_SEAT_PRICINGS_ISLOADING = 'clearSynagogueSeatPricingsIsLoading'

// synagogue_member_seat
export const ADD_SYNAGOGUE_MEMBER_SEAT = 'registerSynagogueMemberSeat'
export const GET_SYNAGOGUE_MEMBER_SEATS = 'getSynagogueMemberSeats'
export const DELETE_SYNAGOGUE_MEMBER_SEAT = 'deleteSynagogueMemberSeat'
export const EDIT_SYNAGOGUE_MEMBER_SEAT = 'editSynagogueMemberSeat'
export const CLEAR_SYNAGOGUE_MEMBER_SEATS_ISLOADING = 'clearSynagogueMemberSeatsIsLoading'

// receipt
export const GET_RECEIPTS = 'getReceipts'
export const GET_MEMBER_RECEIPTS = 'getMemberReceipts'
export const CLEAR_RECEIPTS_ISLOADING = 'clearReceiptsIsLoading'

// member_type
export const ADD_MEMBER_TYPE = 'addMemberType'
export const GET_MEMBER_TYPES = 'getMemberTypes'
export const DELETE_MEMBER_TYPE = 'deleteMemberType'
export const EDIT_MEMBER_TYPE = 'editMemberType'
export const CLEAR_MEMBER_TYPES = 'clearMemberTypes'
export const CLEAR_MEMBER_TYPES_ISLOADING = 'clearMemberTypesIsLoading'

// pledge_item
export const ADD_PLEDGE_ITEM = 'addPledgeItem'
export const ADD_BULK_MEMBERS_PLEDGE_ITEMS = 'addBulkMembersPledgeItems'
export const GET_PLEDGE_ITEMS = 'getPledgeItems'
export const DELETE_PLEDGE_ITEM = 'deletePledgeItem'
export const EDIT_PLEDGE_ITEM = 'editPledgeItem'
export const PLEDGE_PLEDGE_ITEM = 'payPledgeItem'
export const CLEAR_PLEDGE_ITEMS_ISLOADING = 'clearPledgeItemsIsLoading'

// organization_screen
export const REGISTER_ORGANIZATION_SCREEN = 'registerOrganizationScreen'
export const GET_ORGANIZATION_SCREENS = 'getOrganizationScreens'
export const DELETE_ORGANIZATION_SCREEN = 'deleteOrganizationScreen'
export const EDIT_ORGANIZATION_SCREEN = 'editOrganizationScreen'
export const CLEAR_ORGANIZATION_SCREENS_ISLOADING = 'clearOrganizationScreensIsLoading'

// pledge_item_payment_log
export const GET_PLEDGE_ITEM_PAYMENT_LOGS = 'getPledgeItemPaymentLogs'
export const GET_SINGLE_PLEDGE_ITEM_PAYMENT_LOGS = 'getSinglePledgeItemPaymentLogs'
export const CLEAR_PLEDGE_ITEM_PAYMENT_LOGS_ISLOADING = 'clearPledgeItemPaymentLogsIsLoading'

// donation_report
export const GET_DONATION_REPORT_STATS = 'getDonationReportStats'
export const GET_DONATION_REPORT = 'getDonationReport'
export const GENERATE_DONATION_REPORT_FILE = 'generateDonationReportFile'
export const GET_DONATION_REPORT_FILES = 'getDonationReportFiles'
export const CLEAR_DONATION_REPORTS_ISLOADING = 'clearDonationReportsIsLoading'

// member_report
export const GENERATE_MEMBER_STATEMENT_FILE = 'generateMemberStatementFile'
export const GET_MEMBER_STATEMENT_FILES = 'getMemberStatementFiles'
export const CLEAR_MEMBER_STATEMENTS_ISLOADING = 'clearMemberStatementsIsLoading'

// report_file
export const DELETE_REPORT_FILE = 'deleteReportFile'
export const CLEAR_REPORT_FILES_ISLOADING = 'clearReportFileIsLoading'

// pledge_item_deletion_reason
export const GET_PLEDGE_ITEM_DELETION_REASON = 'getPledgeItemDeletionReason'
export const ADD_PLEDGE_ITEM_DELETION_REASON = 'addPledgeItemDeletionReason'
export const CLEAR_PLEDGE_ITEM_DELETION_REASONS_ISLOADING = 'clearPledgeItemDeletionReasonsIsLoading'

// pledge_item_edit_reason
export const GET_PLEDGE_ITEM_EDIT_REASON = 'getPledgeItemEditReason'
export const ADD_PLEDGE_ITEM_EDIT_REASON = 'addPledgeItemEditReason'
export const CLEAR_PLEDGE_ITEM_EDIT_REASONS_ISLOADING = 'clearPledgeItemEditReasonsIsLoading'

// pledge_type
export const ADD_PLEDGE_TYPE = 'addPledgeType'
export const GET_PLEDGE_TYPES = 'getPledgeTypes'
export const DELETE_PLEDGE_TYPE = 'deletePledgeType'
export const EDIT_PLEDGE_TYPE = 'editPledgeType'
export const CLEAR_PLEDGE_TYPES_ISLOADING = 'clearPledgeTypesIsLoading'

// pledge_type
export const ADD_ALIAS = 'addAlias'
export const GET_ALIASES = 'getAliases'
export const DELETE_ALIAS = 'deleteAlias'
export const EDIT_ALIAS = 'editAlias'
export const CLEAR_ALIASES_ISLOADING = 'clearAliasesIsLoading'

// module_setting
export const ADD_MODULE_SETTING = 'addModuleSetting'
export const GET_MODULE_SETTINGS = 'getModuleSettings'
export const CLEAR_MODULE_SETTINGS_ISLOADING = 'clearModuleSettingsIsLoading'

// stats
export const GET_DASHBOARD_STATS = 'getDashboardStats'
export const CLEAR_STATS_ISLOADING = 'clearDonationIsLoading'

// router_loader 
export const UPDATE_ROUTER_LOADER = 'updateRouterLoader'

// error 
export const CLEAR_ERRORMSG = 'clearErrorMessage'
export const UPDATE_ERRORMSG = 'updateErrorMessage'