import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import multiguard from 'vue-router-multiguard';
import store from '@/store';
import Dashboard from '../views/Dashboard.vue'
import { ACCOUNT_ROUTE, ALERT_ROUTE, ANNOUNCEMENTS_ROUTE, DASHBOARD_ROUTE, DISPLAY_ROUTE, DONATIONS_ROUTE, LOGIN_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_ANNOUNCEMENTS_ROUTE, ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_DONATIONS_ROUTE, ORGANIZATION_MEMBERS_ROUTE, ORGANIZATION_MINYANIM_ROUTE, ORGANIZATION_NOTIFICATIONS_ROUTE, ORGANIZATION_SCREENS_ROUTE, ORGANIZATION_SYNAGOGUES_ROUTE, ORGANIZATION_SHIURIMS_ROUTE, ORGANIZATION_TEMPLATES_ROUTE, ORGANIZATIONS_ROUTE, SCREENS_ROUTE, TEMPLATES_ROUTE, TICKETS_ROUTE, ORGANIZATION_INVOICES_ROUTE, MEMBER_INVOICES_ROUTE, MEMBERS_ROUTE, MEMBER_DETAILS_ROUTE, MEMBER_DONATIONS_ROUTE, MEMBER_SEATS_ROUTE, MEMBER_PURPOSES_ROUTE, MEMBER_INVOICE_PAYMENTS_ROUTE, MEMBER_RECEIPTS_ROUTE, MEMBER_PLEDGES_ROUTE, MEMBER_PLEDGE_ITEM_PAYMENT_LOGS_ROUTE, DONATION_REPORT_STATS_ROUTE, REPORT_SEATS_ROUTE, REPORT_PLEDGES_ROUTE, DONATION_REPORT_ROUTE, SYNAGOGUES_ROUTE, ORGANIZATION_SETTINGS_ROUTE, ORGANIZATION_ALIASES_ROUTE } from './routes'
import { ifNotAuthDeny } from './guards/not_auth'
import { ifAuthDeny } from './guards/auth'
import { clearDisplayDonations, updateAuthAccount, updateSettings } from './router_methods'
import { CLEAR_ERRORMSG, GET_MODULE_SETTINGS, UPDATE_ROUTER_LOADER } from '@/store/action_types';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: DASHBOARD_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: Dashboard
  },
  {
    path: '/login',
    name: LOGIN_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('@/views/auth/Login2.vue')
  },
  {
    path: '/display/:id/:org',
    name: DISPLAY_ROUTE,
    beforeEnter: multiguard([ifNotAuthDeny, clearDisplayDonations]),
    component: () => import('@/views/display/Display.vue')
  },
  {
    path: '/donations',
    name: DONATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Donations.vue')
  },
  {
    path: '/seats',
    name: SYNAGOGUES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Synagogues.vue')
  },
  {
    path: '/templates',
    name: TEMPLATES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Templates.vue')
  },
  {
    path: '/screens',
    name: SCREENS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Display_Screens.vue')
  },
  {
    path: '/announcements',
    name: ANNOUNCEMENTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Announcements.vue')
  },
  {
    path: '/organizations',
    name: ORGANIZATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Organizations.vue')
  },
  {
    path: '/organizations/:id/details',
    name: ORGANIZATION_DETAILS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Details.vue')
  },
  {
    path: '/organizations/:id/accounts',
    name: ORGANIZATION_ACCOUNTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Accounts.vue')
  },
  {
    path: '/organizations/:id/donations',
    name: ORGANIZATION_DONATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Donations.vue')
  },
  {
    path: '/organizations/:id/minyanim',
    name: ORGANIZATION_MINYANIM_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Minyanim.vue')
  },
  {
    path: '/organizations/:id/announcements',
    name: ORGANIZATION_ANNOUNCEMENTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Announcements.vue')
  },
  {
    path: '/organizations/:id/shiurim',
    name: ORGANIZATION_SHIURIMS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Shiurim.vue')
  },
  {
    path: '/organizations/:id/screens',
    name: ORGANIZATION_SCREENS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Screens.vue')
  },
  {
    path: '/organizations/:id/members',
    name: ORGANIZATION_MEMBERS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Members.vue')
  },
  {
    path: '/organizations/:id/synagogues',
    name: ORGANIZATION_SYNAGOGUES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Synagogues.vue')
  },
  {
    path: '/organizations/:id/templates',
    name: ORGANIZATION_TEMPLATES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Templates.vue')
  },
  {
    path: '/organizations/:id/aliases',
    name: ORGANIZATION_ALIASES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Alias.vue')
  },
  {
    path: '/organizations/:id/invoices',
    name: ORGANIZATION_INVOICES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Invoices.vue')
  },
  {
    path: '/organizations/:id/notifications',
    name: ORGANIZATION_NOTIFICATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Notifications.vue')
  },
  {
    path: '/organizations/:id/settings',
    name: ORGANIZATION_SETTINGS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Settings.vue')
  },
  {
    path: '/members',
    name: MEMBERS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Member/Members.vue')
  },
  {
    path: '/member/:membId/:orgId/:name',
    name: MEMBER_DETAILS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Member/Details.vue')
  },
  {
    path: '/member/:membId/:orgId/:name/donations',
    name: MEMBER_DONATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Member/Donations.vue')
  },
  {
    path: '/member/:membId/:orgId/:name/purpose',
    name: MEMBER_PURPOSES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Member/Purposes.vue')
  },
  {
    path: '/member/:membId/:orgId/:name/seats',
    name: MEMBER_SEATS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Member/Seats.vue')
  },
  {
    path: '/member/:membId/:orgId/:name/invoices',
    name: MEMBER_INVOICES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Member/Invoices.vue')
  },
  {
    path: '/member/:membId/:orgId/:name/invoices/payments',
    name: MEMBER_INVOICE_PAYMENTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Member/Invoice_Payments.vue')
  },
  {
    path: '/member/:membId/:orgId/:name/receipts',
    name: MEMBER_RECEIPTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Member/Receipts.vue')
  },
  {
    path: '/member/:membId/:orgId/:name/pledges',
    name: MEMBER_PLEDGES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Member/Pledges.vue')
  },
  {
    path: '/member/:membId/:orgId/:name/pledges-items/payments',
    name: MEMBER_PLEDGE_ITEM_PAYMENT_LOGS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Member/Pledge_Payments.vue') 
  },
  {
    path: '/report/donation/stats',
    name: DONATION_REPORT_STATS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/report/Donation/Donation_Stats.vue')
  },
  {
    path: '/report/donation/report',
    name: DONATION_REPORT_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/report/Donation/Donation_Report.vue')
  },
  {
    path: '/report/seats',
    name: REPORT_SEATS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/report/Donation/Donation_Stats.vue')
  },
  {
    path: '/report/pledges',
    name: REPORT_PLEDGES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/report/Donation/Donation_Stats.vue')
  },
  {
    path: '/accounts',
    name: ACCOUNT_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Users.vue')
  },
  {
    path: '/alerts',
    name: ALERT_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Alerts.vue')
  },
  {
    path: '/tickets',
    name: TICKETS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Tickets.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters.sessionToken != null && store.getters.account.data != null) {
    store.dispatch(GET_MODULE_SETTINGS)
  }

  store.dispatch(UPDATE_ROUTER_LOADER, true)
  store.dispatch(CLEAR_ERRORMSG)

  setTimeout(() => {
    next();
  }, 1);
})

router.afterEach((to, from, next) => {
  store.dispatch(UPDATE_ROUTER_LOADER, false)

})

export default router